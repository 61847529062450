.heading {
  font-size: 1.5rem;
  text-align: left;
  color: var(--brand-color);
  margin-left: 15%;
  margin-bottom: 10px;
}

.progress_container {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.progress_state {
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.5;
}

.progress_state div {
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_state p {
  font-size: 1.1rem;
}

.progress_between {
  height: 2px;
  width: 100px;
  background-color: lightgrey;
}

.active_progress_state {
  opacity: 1;
}

.active_progress_state div {
  background: var(--brand-color);
  color: white;
  border: none;
}
.active_progress_state p {
  font-weight: 500;
}

.footer_actions {
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  height: 60px;
  background-color: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
  z-index: 100;
}

.footer_actions button {
  height: 35px;
  border: none;
  outline: none;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.blue_btn {
  background: var(--brand-color);
  color: white;
}

.footer_actions button svg {
  height: 16px;
  width: 16px;
}

.blue_btn svg path {
  fill: white;
}

.configuration_container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-inline: auto;
  /* padding: 30px 0; */
  border: 2px dashed var(--brand-color);
  padding: 50px;
  border-radius: 15px;
  background: #f2f2f2;
}

.import_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  gap: 1rem;
  /* border: 1px solid red; */
}


.choose_btn {
  background: var(--brand-color);
  color: white;
  border-radius: 5px;
  display: flex;
  height: 35px;
  width: fit-content;
  align-items: center;
  position: relative;
}
.choose_btn button{
  background-color: transparent;
  color: white;
  height: 100%;
  width: 100%;
  border: none;
  padding-inline: 10px;
  border-radius: 5px;
  font-size: 0.95rem;

  height: 35px;
}

.choose_btn p {
  padding-inline: 10px;
}

.choose_btn input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.choose_btn_icon_section {
  height: 100%;
  aspect-ratio: 1/1;
  border-left: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.choose_btn svg {
  height: 15px;
  width: 15px;
}

.choose_btn svg path {
  fill: white;
}

.choose_btn_drop_down {
  position: absolute;
  height: 300px;
  width: 300px;
  background: white;
  top: 110%;
  right: 0;
}

.import_icon {
  background: white;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* border: 1px solid red; */
}

.import_icon svg {
  height: 40px;
  width: 40px;
}

.sample_text {
  font-size: 0.8rem;
  margin-top: 5px;
}

.sample_text span {
  color: var(--brand-color);
  text-decoration: underline;
}

.files {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid grey;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;
}

.file {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.file_icon {
  height: 80px;
  width: 80px;
}

.file_icon path {
  stroke: var(--brand-color);
}

.file_name {
  font-size: 0.8rem;
}

.remove_file {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.remove_file svg {
  height: 0.9rem;
  width: 0.9rem;
}

.container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: 0 auto;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.copyAllButton , .exportButton {
  padding: 10px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem;
  margin-right: 10px;
}
.exportButton {
  background-color: #007bff;
}
.copyAllButton:disabled , .exportButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
  padding: 12px 16px;
  text-align: left;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
  color: #555;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.copyButton  {
  padding: 6px 10px;
  /* background-color: #007bff; */
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  background-color: rgb(227, 225, 225);
}

.copyButton:hover {
  background-color: grey;
}

.copyButton:disabled {
  /* background-color: #6c757d; */
  cursor: not-allowed;
}

.doneButton {
 color: green;
 font-size: 1.1rem;
  background-color: rgb(227, 225, 225);
  /* cursor: not-allowed; */
}

.copiedRow {
  background-color: #e6ffe6;
}



.close {
  position: absolute;
  top: 7px;
  right: 15px;
  cursor: pointer;
  
  font-size: 2rem;
}
.imageGrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-block: 10px;
  overflow-y: auto;
  gap: 20px;
  
}

.imageContainer{
  position: relative;
}

.imageContainer img{
  height: 200px;
  width: 100%;
  border: 1px solid rgb(138, 138, 138);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modalHeader {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.modalActions {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-around;
}

.modalButton {
  background-color: #007bff;
  color: white;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.modalButton:hover {
  background-color: #0056b3;
}

.modalButtonCancel {
  background-color: #dc3545;
}

.modalButtonCancel:hover {
  background-color: #c82333;
}