.search_section {
  display: flex;
  margin-left: auto;
  height: 37px;
  width: 300px;
  border-radius: 10px;
  background: white;
  overflow: hidden;
  align-items: center;
  padding-left: 10px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.search_section svg {
  height: 17px;
  color: lightgrey;
}

.search_section input {
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9rem;
  padding: 10px;
  flex: 1;
}
