/* ShippingLabel.module.css */
.outer_shipping_label{
    padding: 1rem;
    background: #fff;
    height: 100%;
    overflow-y: auto;
    border-radius: 14px 0px 0px 14px;
}

.shippingLabel {
  border: 2px solid black;
  border-radius: 8px;
  padding: 20px;
  /* display: grid;
  grid-template-rows: 0.5fr 0.7fr 0.8fr 0.8fr ; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.header , .second_head{
  display: grid;
  grid-template-columns: 0.85fr 1fr;
  width: 100%;
  grid-column-gap: 1px;
  /* background-color: black; */
  /* align-items: center; */
  margin-bottom: 20px;
  border-bottom: 2px solid black;
  /* border: 1px solid red; */
}
.header{
  grid-template-columns: 1fr 2fr;
}

.header > span , .shippedBy{
    width: 100%;
    background-color: white;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-inline: 5px;
}

.shipTo ,.paymentMode{
    /* display: grid; */
    display: flex;
    flex-direction: column;
   justify-content: center;
    background-color: white;
    padding-inline: 1rem;
    /* align-items: center; */
}

.logo {
  height: auto;
  width: 50px;
}

.serviceType label {
  font-weight: bold;
  font-size: 0.7rem;
}
.packageDetails{
    border-bottom: 2px solid black;
    padding-bottom: 4px;
}
.packageDetails > span{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding-block: 3px;
    /* flex-wrap: wrap; */
}

.packageDetails > span > div{
    display: flex;
    gap: 4px;
}

.serviceType span {
  display: block;
  margin-block: 5px;
  font-size: 0.9rem;
}


.shippedBy h3, .shipTo h3, .packageDetails h3 , .paymentMode h3 , .third_header h3{
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.94rem;
}

.shippedBy p, .shipTo p {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8rem;
  text-align: left;
}
.third_header{
    /* padding-block: 1rem; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    grid-column-gap: 1.5px;
    background-color: black;
    /* padding-block: 2px; */
}


.instructions{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.company_logo{
    background-color: white;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 10px;
}

.company_logo img{
    height: auto;
    width: 100px;
}