.container {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  height: 20px;
  width: 20px;
}

.HoverContainer {
  position: absolute;
  z-index: 10;
  top: 100%;
  /* left: 0; */
  background-color: rgba(0, 0, 0, 0.5);
  width: 200px;
  border-radius: 8px;
  padding: 5px 10px;
  opacity: 0;
  transition: opacity 0.5s ease; /* Adding transition for smooth hiding/unhiding effect */
  word-break: break-all;
  color: white;
  display: none;
}

.data {
  font-size: 0.8rem !important;
  line-height: 1.2rem !important;
}

.container:hover > .HoverContainer {
  opacity: 1;
  display: block;
}
