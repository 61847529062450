.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.card {
  width: 500px;
  padding: 20px;
  background: white;
  border-radius: 10px;
}

.btn_container {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 30px;
}

.btn_container button {
  flex-grow: 1;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn_container button:last-child {
  background-color: #ff313a;
  color: white;
}
