.heading {
  font-size: 1.5rem;
  text-align: center;
  color: var(--brand-color);
}

.progress_container {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.progress_state {
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.5;
}

.progress_state div {
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_state p {
  font-size: 1.1rem;
}

.progress_between {
  height: 2px;
  width: 100px;
  background-color: lightgrey;
}

.active_progress_state {
  opacity: 1;
}

.active_progress_state div {
  background: var(--brand-color);
  color: white;
  border: none;
}
.active_progress_state p {
  font-weight: 500;
}

.footer_actions {
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  height: 60px;
  background-color: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
  z-index: 100;
}

.footer_actions button {
  height: 35px;
  border: none;
  outline: none;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.blue_btn {
  background: var(--brand-color);
  color: white;
}

.footer_actions button svg {
  height: 16px;
  width: 16px;
}

.blue_btn svg path {
  fill: white;
}
