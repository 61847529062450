.topBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 10px;
}
.topBtn > button {
  padding: 5px 10px;
  background-color: rgb(57, 126, 230);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.01rem;
}
.button {
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: background-color 200ms ease-in-out;
}

.primary {
  background-color: #3b82f6;
  color: white;
}

.primary:hover {
  background-color: #2563eb;
}

.gridButton button {
  background-color: transparent;
  border: 1px solid rgb(57, 126, 230);
  padding: 6px 10px;
  cursor: pointer;
  color: rgb(57, 126, 230);
}
.gridButton button svg {
  font-size: 14px;
  display: grid;
  place-items: center;
}

.gridButton .list {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.gridButton .grid {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.filterGroup {
  display: flex;
  gap: 16px;
}

.filterButton {
  position: relative;
  padding: 6px 12px;
  background-color: rgba(57, 126, 230, 1);
  color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.redDot {
  position: absolute;
  top: 4px;
  right: 24px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.arrowDown {
  margin-left: 8px;
  /* font-size: 16px; */
  height: 20px;

  color: white;
  /* border: 1px solid red; */
  height: fit-content;
}

.dropdown {
  position: relative;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  min-width: 150px;
  width: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdownContent div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdownContent div:hover {
  background-color: #f3f3f3;
}

.customDateDropdown {
  position: absolute;
  bottom: 0;
  left: calc(100%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.btnGroup {
  display: flex;
  gap: 10px;
}
.btnGroup button {
  color: rgb(57, 126, 230);
  background-color: transparent;
  border: none;
  justify-content: space-between;
  font-weight: 600;
  cursor: pointer;
}
.btnGroup button:first-child {
  color: red;
}
.customDateDropdown input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
/* browseMedia.module.css */

.imageGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.imageItem {
  width: 100%;
  padding: 8px;
  /* position: relative; */
  cursor: pointer; /* Change cursor to pointer on hover */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow for rounded corners */
  /* color: #e0dede; */
}
.imageItem p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imageName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  font-size: 0.85rem;
}
.imageList {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin: 20px; /* Margin around the list */
}

.imageCard {
  display: flex;
  position: relative;
  align-items: center; /* Center items vertically */
  padding: 6px 10px;
  margin: 5px;
  background-color: white; /* Default background color */
  border: 1px solid lightgrey; /* Default border */
  border-radius: 8px; /* Rounded corners */
}

.imageCard:hover {
  background-color: rgba(57, 126, 230, 0.1); /* Light hover effect */
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modalContent {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 25px;
  width: 50vw;
  min-height: 50vh;
  max-height: 70vh; /* Limit height for scrolling */
  overflow-y: auto; /* Enable scrolling if content overflows */
  position: relative;
}

.modalContent h2 {
  text-align: center;
  margin-block: 10px 15px;
}

.searchBar {
  flex: 1; /* Take up available space */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.searchBar:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.searchBtn {
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.searchBtn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.productList {
  margin-top: 15px; /* Space between search bar and product list */
}

.productItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0; /* Light border for separation */
  transition: background-color 0.3s;
}

.productItem:hover {
  background-color: #f9f9f9; /* Light background on hover */
}

.productItem span {
  font-size: 14px; /* Font size for product title and SKU */
  color: #333; /* Dark text color */
}

.productItem button {
  background-color: #007bff; /* Success button color */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.productItem button:hover {
  background-color: #007bff; /* Darker shade on hover */
}
