.mainContainer {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 20px;
}
.mapContainer {
  width: fit-content;
  padding-inline: 20px;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
}

.mapContainer svg {
}
.mapContainer svg path:hover {
  stroke: black !important;
  stroke-width: 2;
  cursor: pointer;
  fill: var(--blue-light) !important;
}
.SideContainer {
  flex-grow: 1;
  flex-shrink: 0;

  /* background-color: antiquewhite; */
}

.heading {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  /* border-bottom: 1px gray solid; */
  padding: 10px 0;
}

.heading > h5 {
  font-size: 1rem;
  color: gray;
  font-weight: 400;
  border-bottom: 1px dashed gray;
  padding-bottom: 5px;
}
.mainData {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 5px;
  align-items: center;
  justify-content: center;
}
