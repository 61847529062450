.container {
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  gap: 5px;
}

.singleCard {
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 1.1rem;
}

.selected {
  background: black;
  color: white;
}

.singleCard:hover {
  background-color: lightgrey;
  transition: 500ms;
}
