.productH1 {
  font-size: 1.6rem;
}

.tableContainer {
  width: 100%;
  height: 500px;
  overflow-y: auto;
}

.table {
  width: 100%;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.tableBody {
}

.heading {
  width: 85%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: start;
}

.quantity {
  width: 15%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}
.btn {
  width: fit-content;
  padding: 5px 20px;
  border-radius: 8px;
  margin-top: 5px;
  border: none;
  color: #397ee6;
  background: none;
  /* background-color: #397EE6; */
  list-style: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.btnContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 8px;
}
