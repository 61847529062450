.container {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.cell {
  border-bottom: 1px solid lightgrey;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  height: 42px;
}

.head {
  background: rgba(57, 126, 230, 0.2);
  border: none;
}

.loading_box {
  height: 20px;
  width: 80%;
  background: rgb(234, 234, 234);
  border-radius: 5px;
}

.loading_animation {
  width: 100%;
  height: 20px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #e6e6e6 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 5px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
