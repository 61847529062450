.filterForm{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0 50px;
}
select{
    width: 10%;
    padding: 5px; 
    border-radius: 6px;
}