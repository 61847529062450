.container {
  width: 100%;
  /* background-color: antiquewhite; */
}

.heading {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  /* border-bottom: 1px gray solid; */
  padding: 10px 0;
}

.heading > h5 {
  font-size: 0.9rem;
  color: gray;
  font-weight: 400;
  border-bottom: 1px dashed gray;
  padding-bottom: 5px;
}
.mainData {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 5px;
  align-items: center;
  justify-content: center;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.btn {
  display: inline-flex;
  background: none;
  align-items: center;
  gap: 5px;
  color: #397ee6;
}
