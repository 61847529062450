.invoiceContainer {
  width: 80mm; /* Typical width for thermal paper */
  padding: 20px;
  font-family: Arial, sans-serif;
  /* border: 1px solid #000; */
}
.invoiceContainer h4{
  font-size: 10px;
  text-align: center;
}
.details_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  font-size: 10px; /* Adjust as needed */
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  margin-block:10px 10px;
  font-weight: 200;
  /* border: 1px solid red; */
  justify-content: space-between;

}

.details-grid div {
  display: flex;
  justify-content: space-between;
  padding: 2px;
}


.details-grid span {
  white-space: nowrap;
}

.title {
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
}
.header{
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

.header, .footer {
    text-align: center;
    margin-bottom: 5px;
}


.item_heading{
  display: grid;
    grid-template-columns: repeat(5,1fr);
  justify-content: space-between;
  border-bottom: 1px solid #DDD;
  /* padding-left: 20px; */
  font-size: 0.7rem;
  font-weight: 400;
  gap: 5px;
}

.items {
    margin-bottom: 5px;
     border-bottom: 1px solid #DDD;
}

.item {
    margin-bottom: 5px;
}

.itemTitle {
    font-weight: 500;
    font-size: 0.65rem;
    margin-block: 4px;
    text-transform: capitalize;
}

.itemDetails {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-content: space-between;
    font-size: 9px;
    margin-top: 2px;
    /* padding-left: 20px; */
}

.total {
    margin-top: 5px;
    font-weight: bold;
    /* text-align: right; */
    font-size: 0.8rem;
    display: grid;
    grid-template-columns: repeat(5,1fr);
       justify-content: space-between;
    /* justify-content: right; */
    /* border: 1px solid red; */

    /* display: flex; */
}

/* .total > div{
  display: flex;
  justify-content: space-between;
  gap: 10px;
} */

.taxes{
  margin-block: 1rem;
}

.taxes p{
  font-size: 0.6rem !important;
  text-align: right;
  /* border: 1px solid red; */
}

.total h4{
  min-width: 2rem;
  font-size: 0.65rem;
  min-width: 2rem;
}
.footer {
    text-align: center;
    margin-top: 10px;
    border-top: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.totalWords {
    text-align: left;
    margin-top: 10px;
    font-size: 0.65rem;
}
.user{
  display: flex;
  flex-direction: column;
   gap: 5px;

}
.user p{
  font-size: 10px;
  text-align: left;
}