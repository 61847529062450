.heading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.heading span {
  font-size: 1rem;
  background: var(--blue-light);
  color: var(--blue-dark);
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.tabs {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.tabs div {
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  opacity: 0.5;
  cursor: pointer;
}

.active {
  opacity: 1 !important;
}

.tabs span {
  margin-left: auto;
  margin-right: 20px;
  background: var(--blue-light);
  color: var(--blue-dark);
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
}
