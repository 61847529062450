.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-inline: auto;
  padding: 30px 0;
  gap: 20px;
}

.selected_files {
  font-size: 0.9rem;
}

.info_text_with_icon {
  background: var(--blue-light);
  color: var(--brand-color);
  padding: 10px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}

.info_text_with_icon svg {
  height: 1rem;
  width: 1rem;
}

.info_text_with_icon svg path {
  stroke: var(--brand-color);
}

.header {
  display: flex;
  background: #e2e2e2;
}

.header div:first-child {
  min-width: 200px;
}

.contact_table table,
.contact_table th,
.contact_table td {
  border: none;
}

.contact_table table th,
.contact_table table td {
  min-width: 200px;
  text-align: left;
  height: 40px;
  padding-left: 10px;
}

.contact_table table th {
  font-size: 0.8rem;
  font-weight: 400;
  background: #e2e2e2;
}

.contact_table table td {
  font-size: 0.9rem;
  font-weight: 400;
  height: 60px;
}

.drop_container {
  width: 300px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 5px;
  display: inline-flex;
  padding: 10px;
  align-items: center;
  position: relative;
}

.drop {
  max-height: 300px;
  width: 100%;
  position: absolute;
  overflow-y: auto;
  background: white;
  z-index: 150;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  left: 0; /* Default left position; will be overridden by inline styles */
}

.drop > div {
  padding: 10px;
}

.drop > div:hover {
  background: var(--blue-light);
  cursor: pointer;
}

.drop_cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  z-index: 110;
}

.drop_container p {
  flex: 1;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.actions div {
  height: 10px;
  width: 1px;
  background: grey;
}

.actions svg {
  cursor: pointer;
}

.closeIcon {
  color: red;
}
