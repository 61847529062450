.container{
    width: 100%;
    padding: 5px;
    /* height: 200vh; */
    overflow-y: auto;
}
.Heading{
    font-weight: 400;
    color: #397EE6;
    font-size: 1.2rem;
}
.filterContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:0 10px ;
}
.chartContainer{
    min-height: 1200px;
}
.Top{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
.Link{
    display: flex;
    align-items: center;
    justify-content: center;
}