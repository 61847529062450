.container {
  width: 100%;
  height: fit-content;
}

.table_main {
  width: 100%;
  overflow: auto;
}

.table_container {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  overflow-x: auto;
}

.table_container thead th {
  background-color: rgba(57, 126, 230, 0.2);
  font-size: 0.8rem;
  font-weight: 500;
  height: 42px;

  text-align: left;
}

.table_container td {
  font-size: 0.9rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.table_container tbody tr {
  border-bottom: 1px solid lightgrey;
}

.table_container td,
.table_container th {
  padding-inline: 10px;
}

.table_container tbody tr td {
  color: grey;
  white-space: wrap;
  font-size: 0.7rem;
  text-align: center;
}

.table_container thead tr th {
  text-align: center;
}

.table_container thead tr th:first-child {
  text-align: left;
}

.table_container tbody tr td:first-child {
  color: black;
  white-space: nowrap;
  padding-left: 8px;
  font-size: 0.9rem;
  text-align: left;
}

.table_container thead tr th:first-child {
  padding-left: 8px;
}

.table_container tbody tr:hover {
  background: var(--blue-light);
  cursor: pointer;
}

a {
  background-color: #397ee6;
  color: white;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;

  /* margin-top: 50px !important; */
}

.admin {
  margin: 15px 0;
}
.btn,
.btn_cancel {
  padding: 0;
  font-size: 0.9rem;
  align-items: center;
  display: flex;
  padding-inline: 10px;
  background-color: var(--blue-light);
  border: none;
  color: var(--blue-dark);
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn_cancel {
  cursor: not-allowed;
  position: relative;
}

.btn_cancel:hover::after {
  content: "Member limit reached. Contact your account manager to add more Members.";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 0.8rem;
  width: 200px;
  right: 0;
  top: 100%;
  padding: 5px;
  border-radius: 10px;
}

.del_icon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
