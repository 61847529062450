.container {
  position: relative;
  display: inline-block;
  background: rgba(57, 126, 270);
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.transparent{
  background-color: transparent;
  color: black;
  border-left: 1px solid rgb(219, 214, 214);
  border-radius: 0px;
}


.main {
  height: 100%;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.transparent .main{
  padding-right: 4px;
}

.drop_part {
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  border-left: 1px solid white;
}

.drop_container {
  position: absolute;
  top: 110%;
  right: 0;
  width: fit-content;
  background: white;
  color: black;
  border-radius: 5px;
  /* display: none; */
  z-index: 1;
  list-style: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 110;
}

.drop_container li {
  padding: 7px 20px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.drop_container li:hover {
  background: var(--brand-color);
  color: white;
}

.drop_container li {
  border-bottom: 1px solid #ccc;
}

.cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 100;
}
