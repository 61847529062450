.media_container {
  width: 100%;
  height: 200px;
  border: 1px dashed var(--blue-dark);
  border-radius: 20px;
  background: var(--blue-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.media_container p {
  color: var(--blue-dark);
  font-size: 1.2rem;
  font-weight: 500;
}

.media_container input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.table_container {
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid grey;
  font-weight: 500;
  font-size: 1.1rem;
  color: grey;
}

.table th {
  padding: 5px 10px;
}

.table tr:hover td {
  background: rgba(100, 100, 111, 0.2);
}

.table td {
  padding: 10px;
}

.table_container_head {
  display: flex;
  justify-content: space-between;
}

.table_pagination {
  display: flex;
  justify-content: center;
}
