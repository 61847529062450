.container{
    width: 100%;
    padding: 5px;
}
.Heading{
    font-weight: 400;
    padding: 0 8px;
    color: #397EE6;
    font-size: 1.2rem;
}
.filterContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:0 10px ;
}
.chartContainer{

}
.Top{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
.Link{
    display: flex;
    align-items: center;
    justify-content: center;
}