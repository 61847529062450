.container {
  height: 100%;
  margin-right: 15px;
  display: flex;
  gap: 10px;
}

.select_container {
  padding: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  border-radius: 5px;
}

.container select {
  height: 100%;
  border: none;
  background: white;
}

.container select:focus {
  outline: none;
}

.pagination_container {
  height: 100%;
  width: fit-content;
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  border-radius: 5px;
  padding: 5px 10px;
  gap: 5px;
}

.controls {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_container ul {
  list-style: none;
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  font-size: 0.8rem;
  width: 96px;
}

.pagination_container > ul > li {
  height: 100%;
  aspect-ratio: 1/1;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_container li,
.controls {
  cursor: pointer;
}

.pagination_container li:hover,
.controls:hover {
  border: 1px solid rgba(57, 126, 230);
  border-radius: 100px;
}

.selected {
  background: rgba(57, 126, 230);
  border-radius: 100%;
  color: white;
}
