.tooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltipContent {
  /* width: fit-content; */
  min-width: 100%;
  max-width: 200px;
  text-align: justify;
  position: absolute;
  z-index: 50;
  padding: 0.75rem 1rem;
  font-size: 0.7rem;
  font-weight: 400;
  color: white;
  background-color: #1f2937;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;
  pointer-events: none;
  z-index: 999999999;
}

.arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #1f2937;
  transform: rotate(45deg);
}

/* Positions */
.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}

.top .arrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  margin-top: -4px;
}

.right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px;
}

.right .arrow {
  left: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}

.bottom .arrow {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  margin-bottom: -4px;
}

.left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
}

.left .arrow {
  right: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

/* Show tooltip when container is hovered */
:global(.tooltipContainer:hover) .tooltipContent {
  opacity: 1;
  visibility: visible;
}
