.container {
  display: grid;
  grid-template-columns: repeat(1fr 1fr);
  width: 100%;

  gap: 20px;
}

.details_container {
  grid-column: 1/ 3;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 200px;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.details_first p,
.details_second p {
  font-weight: 500;
}

.details_first span {
  font-weight: 400;
}

.details_second {
}

.orders {
  height: fit-content;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding-bottom: 10px;
}

.orders h2 {
  font-weight: 500;
  padding: 10px;
}
