.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.main {
  height: 100%;
  margin-left: 150%;
  width: 60vw;
  background: #fefefa;
  animation: moveIn 0.3s forwards;
  border-radius: 10px 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
}

@keyframes moveIn {
  from {
    margin-left: 150%;
  }
  to {
    margin-left: 40%;
  }
}

.main_close {
  animation: moveOut 0.3s forwards;
}

@keyframes moveOut {
  from {
    margin-left: 40%;
  }
  to {
    margin-left: 150%;
  }
}

.head {
  height: 50px;
  background: var(--brand-color);
  color: white;
  display: flex;
  align-items: center;
  padding-inline: 20px;
  gap: 20px;
}

.head p {
  font-size: 1.5rem;
}

.head svg {
  height: 20px;
  width: 20px;
  fill: white;
  cursor: pointer;
}

.sections {
  height: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid lightgrey;
}

.section {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.section_selected {
  border-bottom: 2px solid var(--brand-color);
}

.assets_main {
  width: 100%;
  height: calc(100vh - 160px);
  overflow: hidden;
}

.assets_container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.asset {
  width: 100%;
  height: 190px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.asset_selected {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
}

.asset img {
  height: 100%;
  width: 100%;
  object-fit: content;
  object-position: center;
}

.upload_container {
  padding: 10px 20px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.upload_container > p:last-child {
  color: grey;
  margin-top: 10px;
}

.upload_section {
  margin-top: 10px;
  width: 100%;
  height: 500px;
  background: var(--blue-light);
  border-radius: 10px;
  border: 1px dashed var(--blue-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
}

.upload_section input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.upload_section p {
  font-size: 1.3rem;
}

.upload_section div {
  background: var(--brand-color);
  padding: 10px 15px;
  color: white;
  border-radius: 10px;
}

.footer {
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-inline: 20px;
  gap: 10px;
  border-top: 1px solid lightgrey;
}

.footer button {
  height: 35px;
  padding-inline: 20px;
  cursor: pointer;
}

.footer button:first-child {
  background: var(--brand-color);
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 1.1rem;
}

.footer button:last-child {
  background: none;
  border: none;
  color: var(--brand-color);
  font-size: 1.1rem;
}
