/* ShippingLabel.module.css */
.modal_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label_wrapper{
  height: fit-content;
  width: 65rem;
  display: grid;
  grid-template-columns: 2fr 1.8fr;
  gap: 0px;
}
