.invoice {
  width: 220mm; /* A4 width */
  height: 297mm; /* A4 height */
  padding: 15mm;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;

}
.inner_container{
border: 1px solid #ddd;
height: 100%;
/* padding: 10mm; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 3mm;
  padding-bottom: 5mm;
  /* margin-bottom: 10mm; */
}
.header img{
  width: 70px;
  height: auto;
  margin-right: 10px;
}
.companyDetails {
  width: 50%;
}

.invoiceTitle {
  text-align: right;
  align-self: end;
  font-size: 0.9rem;
  color:  grey;
}

.invoiceDetails {
  padding: 3mm;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20mm; */
  border-bottom: 1px solid #DDD;
}
.invoiceDetails p{
  padding-block: 2px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}


.customerDetails {
  text-align: left;
  text-transform: capitalize;
  /* padding: 3mm; */
}
.customerDetails h5{
  border-bottom: 1px solid #DDD;
  padding: 1mm 3mm;
}
.customerDetails div{
  padding: 3mm;
}
/* .table {
  margin-bottom: 20mm;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 5mm;
  text-align: left;
} */

.table {
  width: 100%;
  margin: 0 auto;
}

.table table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-transform: capitalize;
  word-break: keep-all;
}

.table th {
  background-color: #f2f2f2;
  text-align: right;
  font-size: 12px;
  /* font-weight: 600; */
}

.table th[colspan=2] {
  text-align: center;
}


.table td {
  vertical-align: top;
  font-size: 12px;
   text-align: right;
}

.table tr td:first-child{
  text-align: left;
}

.table td:nth-child(2) {
  width: 40%;
}

.summary {
  padding: 3mm;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.amountInWords {
  width: 60%;
}

.totalAmount {
  width: 30%;
  /* border: 1px solid red; */
}
.totalAmount p{
  display: flex;
  padding-block: 1px;
  justify-content: space-between;
}

.footer {
  margin-top: 3mm;
  font-size: 14px;
  padding: 3mm;
}

.signature {
  text-align: right;
  margin-top: 10mm;
}

.signature img {
  width: 60mm;
}
