.table_container {
  overflow-x: auto;
  margin: 0 auto;
  padding: 0px;
  flex: 1;
  width: 100%;
}

.responsive_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
  display: table;
  position: relative;
}

.responsive_table tr:hover {
  background-color: var(--blue-light);
  cursor: pointer;
}

.responsive_table th,
.responsive_table td {
  padding: 12px;
  text-align: left;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: none;
  border-left: none;
  white-space: nowrap; /* Prevent text from wrapping */
}

.responsive_table td {
  font-size: 0.9rem;
}

.responsive_table th {
  background-color: rgb(217, 229, 251);
  color: #495057;
  font-weight: 500;
  font-size: 0.9rem;
}

.responsive_table thead {
  position: sticky;
  top: 0;
  min-width: 100%;
}
