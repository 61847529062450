.field_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.card {
  background: white;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid lightgrey;
}

.toggle {
  position: absolute;
  right: 50px;
}

.card h2 {
  font-weight: 500;
  font-size: 1rem;
  height: fit-content;
  text-transform: uppercase;
}

.grid_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.select_media {
  height: 150px;
  border: 2px dashed var(--blue-dark);
  border-radius: 10px;
  background: var(--blue-light);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  position: relative;
  color: var(--blue-dark);
}

.select_media input {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.selected_media {
  display: flex;
  height: 150px;
  gap: 20px;
  flex-wrap: wrap;
  list-style: none;
}

.selected_media li {
  height: 150px;
  aspect-ratio: 1/1;
  border-radius: 10px;
  position: relative;
  border: 1px solid lightgray;
}

.selected_media li img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.selected_media svg {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: var(--blue-light);
  fill: var(--blue-dark);
  border-radius: 100%;
  padding: 4px;
  right: 0;
  top: 0;
  transform: translate(40%, -40%);
  cursor: pointer;
}

.small_select {
  height: 150px;
  aspect-ratio: 1/1;
  border: 1px dashed var(--blue-dark) !important;
  background: var(--blue-light);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: var(--blue-dark);
}

.small_select input {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.add_btn {
  font-size: 1rem;
  background: var(--blue-light);
  color: var(--blue-dark);
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  border: none;
}

.close_icon {
  position: absolute;
  height: 30px;
  width: 30px;
  background: var(--blue-light);
  fill: var(--blue-dark);
  border-radius: 100px;
  right: 0;
  top: 0;
  cursor: pointer;
  transform: translate(50%, -50%);
  padding: 3.5px;
}

.applicability_list {
  padding-inline: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.applicability_list li {
  background: var(--blue-light);
  padding: 5px 10px;
  border-radius: 5px;
  position: relative;
}

.applicability_list li svg {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: var(--blue-dark);
  fill: var(--blue-light);
  border-radius: 100px;
  padding: 2px;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.custom_field {
  height: 50px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 10px;
  display: flex;
}

.custom_field input {
  height: 100%;
  flex-grow: 1;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
}

.custom_field input:focus {
  outline: none;
  font-size: 1.1rem;
  padding: 10px;
}

.rupees {
  height: 100%;
  padding-inline: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  color: grey;
}

.toggle_switch {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle_switch p {
  width: 150px;
}
