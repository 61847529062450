.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.topCardContainer {
  display: flex;
  font-size: 1.1rem;
  width: 100%;
  margin-bottom: 10px;
}

.header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.date {
  display: flex;
  gap: 5px;
  /* text-align: right; */
  /* width: 100%; */
  justify-self: right;
}

.date input {
  border: none;
  background: rgb(234, 234, 234);
  padding: 3px;
  border-radius: 3px;
}
.btn {
  width: fit-content;
  padding: 5px 20px;
  border-radius: 8px;
  margin-top: 5px;
  border: none;
  color: #397ee6;
  background: none;
  /* background-color: #397EE6; */
  list-style: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.btnContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 8px;
}
