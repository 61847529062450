.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-inline: auto;
  padding: 30px 0;
}

.section_container {
  width: 100%;
  height: fit-content;
}

.header {
  height: 50px;
  font-size: 0.9rem;
  border-top: 1px solid #d2d2d2;
  border-bottom: 0.5px solid #d2d2d2;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.header:hover {
  background: var(--blue-light);
}

.header svg {
  height: 18px;
  width: 18px;
  transform-origin: center;
}

.active_icon {
  transform: rotate(180deg);
}

.active_icon path {
  fill: white;
}

.active_header {
  background: var(--brand-color);
  color: white;
}

.active_header:hover {
  background: var(--brand-color);
}

.content {
  height: 0;
  overflow: hidden;
}

.sub_content {
  padding: 20px 10px;
  display: flex;
  gap: 10px;
  background: #fafafa;
}

.active_content {
  height: fit-content;
}

.content_tags {
  padding: 5px 15px;
  border-radius: 5px;
  color: var(--blue-dark);
  background: var(--blue-light);
  font-size: 0.9rem;
}

.table_hidden_container {
  height: 0;
  overflow: hidden;
}

.active_table_container {
  max-height: 500px;
  height: fit-content;
  overflow-y: auto;
  overflow-x: auto;
}

.table th {
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 10px;
  border: none;
  text-align: left;
  background: #f2f2f2;
}

.table td {
  white-space: nowrap;
  font-size: 0.9rem;
  text-align: left;
  border: none;
}
