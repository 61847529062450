.img_input {
  width: 100px;
  height: 100px;
  background-color: var(--blue-light);
  border-radius: 10px;
  border: 1px dashed var(--blue-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  color: var(--blue-dark);
  cursor: pointer;
  position: relative;
}

.img_container {
  width: 100px;
  height: 100px;
  position: relative;
}

.img_container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.scripts_list_item {
  border: 2px dashed var(--blue-light);
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.scripts_list_item > svg {
  position: absolute;
  background: var(--blue-light);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  padding: 3px;
  cursor: pointer;
}

.scripts_list_item > svg path {
  fill: var(--blue-dark);
}

.img_input input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input p {
  font-size: 16px;
  font-weight: 500;
}

.color_input {
  display: flex;
  align-items: center;
  gap: 20px;
}

.color_input label {
  font-size: 16px;
  font-weight: 400;
}

.color_input input {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.close_icon {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--blue-light);
  height: 25px;
  width: 25px;
  border-radius: 100%;
  transform: translate(50%, -50%);
  padding: 2px;
  cursor: pointer;
}

.close_icon svg {
  fill: var(--blue-dark);
}

.subAdminPanel {
}

.tabs {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.tabs div {
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  opacity: 0.5;
  cursor: pointer;
}

.active {
  opacity: 1 !important;
}

.tabs span {
  margin-left: auto;
  margin-right: 20px;
  background: var(--blue-light);
  color: var(--blue-dark);
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
}
.field_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.inner_box {
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background: white;
  border: 1px solid lightgrey;
}
.inner_box h2 {
  margin-bottom: 1rem;
  font-weight: 500;
}
.inner_box > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}
