.img_container {
}

.img_select {
  background: var(--blue-light);
  width: 100%;
  height: 100px;
  border-radius: 20px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: var(--blue-dark);
  cursor: pointer;
  position: relative;
}

.img_select input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.img {
  height: 150px;
  width: fit-content;
  position: relative;
  margin-top: 15px;
  margin-left: 10px;
}
.img img {
  height: 100%;
  width: auto;
}

.close_icon {
  background: var(--blue-light);
  padding: 10px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}
