.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 20px;
}

.sub_container {
  width: 100%;
  min-height: 100px;
  height: fit-content;
  border-left: 1px solid lightgrey;
  position: relative;
  display: flex;
  padding-bottom: 50px;
}

.dot {
  /* position: absolute; */
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--brand-color);
  /* left: 0;
  top: 0; */
  transform: translateX(-50%) translateY(90%);
  box-shadow: 0 0 10px rgba(0, 150, 255, 0.8),
    /* Stronger main glow */ 0 0 20px rgba(0, 150, 255, 0.6),
    /* Additional spread */ 0 0 30px rgba(0, 150, 255, 0.4); /* Further spread */
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(0, 150, 255, 0.6), 0 0 20px rgba(0, 150, 255, 0.4),
      0 0 30px rgba(0, 150, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 20px rgba(0, 150, 255, 0.8), 0 0 30px rgba(0, 150, 255, 0.6),
      0 0 40px rgba(0, 150, 255, 0.4);
  }
}

.heading {
  padding-left: 5px;
  flex-shrink: 0;
}

.heading p {
  font-size: 1.1rem;
}

.heading p:nth-child(2) {
  font-size: 0.9rem;
}

.heading span {
  font-size: 0.9rem;
  color: grey;
}

.main_content {
  width: 65%;
  margin-left: auto;
}

.history_table_container {
  width: 100%;
  margin-top: 7px;
}
.history_table_container table,
th,
td {
  border: 0.1px solid #d2d2d2;
}

.history_table_container thead tr th {
  font-size: 0.8rem;
  font-weight: 500;
}

.history_table_container tbody tr td {
  font-size: 0.8rem;
}

.history_table_container table {
  width: 100%;
  border-collapse: collapse;
  display: table;
}

.history_table_container th,
td {
  padding: 7px 10px;
  text-align: center;
}
