.labaelInput_box{
   background-color: white;
   border-top-right-radius: 14px;
   border-bottom-right-radius: 14px;
   padding: 1.2rem;
   position: relative;
   
}

.printBtn{
    position: absolute;
    bottom: 2rem;
    width: fit-content;
    background-color: var(--blue-dark);
    border: none;
    color: white;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: 5px;
    right: 2rem;
    cursor: pointer;

}

.label_inner_box{
  border: 2px solid black;
  border-radius: 12px;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  
}
.label_inner_box span{
    display: flex;
    justify-content: space-between;
}

.selectedOptions{
    padding-left: 1rem;
    display: flex;
    /* align-items: center; */
    /* border: 1px solid red; */
    /* gap: 10px; */
    flex-direction: column;
    gap: 5px;
}

.selectedOptions button{
    background-color: transparent;
    color: red;
    border: none;
    cursor: pointer;
    
}
.labaelInput_box select {
    width: 40%;
    padding-inline: 2px 5px;
}
.labaelInput_box input {
    width: 40%;
    padding-inline: 2px 5px;
}
