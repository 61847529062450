.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.card {
  width: 100%;
  height: fit-content;
  padding: 20px;
  background: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.card h3 {
  font-weight: 600;
  font-size: 1.3rem;
}

.card h3 span {
  background: #eff5fc;
  color: #36619b;
  display: inline-block;
  margin-left: 10px;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.text_editor_container {
  grid-column: 1/5;
}

.text_editor_container p {
  font-size: 16px;
  font-weight: 500;
  transform: translateX(5px);
  color: #464646;
}

.media_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  overflow: hidden;
}

.select_media {
  border: 2px dashed lightgrey;
  border-radius: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.selected_media {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
}

.media_image {
  width: 100%;
  aspect-ratio: 1/1;
  /* border: 1px solid black; */
  border-radius: 10px;
  /* overflow: hidden; */
  /* overflow: hidden; */
  position: relative;
}

.media_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}

.media_image svg {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  background: #c1ddfd;
  height: 20px;
  width: 20px;
  padding: 5px;
  border-radius: 100%;
}

.select_media input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
}

.select_media p:first-child {
  background: #eff5fc;
  color: #36619b;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.select_media p:last-child {
  opacity: 0.5;
  margin-top: 10px;
}

.markup_heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.markup_heading span {
  background: #c1ddfd;
  display: block;
  width: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #36619b;
}

.markup_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 10px;
}

.markup_card {
  border: 1px solid rgba(128, 128, 128, 0.324);
  border-radius: 10px;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  row-gap: 10px;
  position: relative;
}

.remove_variant {
  position: absolute;
  height: 30px;
  width: 30px;
  background: rgba(0, 0, 0, 0.406);
  border-radius: 100%;
  right: 0;
  top: 0;
  transform: translateX(40%) translateY(-40%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}

.operation_hours_container,
.inventory_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  grid-column: 1/5;
}

.operation_hour_card,
.inventory_card {
  border: 1px solid rgba(128, 128, 128, 0.324);
  border-radius: 10px;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  row-gap: 10px;
  position: relative;
  width: 100%;
}

.inventory_card {
  grid-template-columns: repeat(2, 1fr);
}

.operation_hour_card > svg,
.inventory_card > svg {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  background: #c1ddfd;
  height: 20px;
  width: 20px;
  padding: 5px;
  border-radius: 100%;
}

.badge_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.badge_container p {
  background: #eff5fc;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #36619b;
}

.badge_container p svg {
  cursor: pointer;
}

/* 
status
variants
*/
