.top_cards {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  list-style: none;
  overflow-x: auto;
  padding-bottom: 20px;
  padding: 5px;
}

.card {
  height: fit-content;
  width: fit-content;
  background: white;
  border-radius: 20px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.card h4 {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}

.card p {
  font-size: 1.5rem;
  font-weight: 800px;
  color: grey;
  white-space: nowrap;
}

.producCard {
  width: 400px;
  min-height: 15vh;
}
.productData h3 {
  font-size: 20px;
  text-transform: capitalize;
  color: grey;
}

.productData p {
  line-height: 10px;
  color: grey;
  /* word-break:break-all; */
  word-wrap: break-word;
  width: 400px;
  font-size: 15px;
  white-space: nowrap;
  height: fit-content;
}

.productData div {
  word-break: break-all;
  word-wrap: break-word;
  width: 400px;
}
.Header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Header h4 {
  font-size: 1rem;
  font-weight: 600;
}
.Header span {
  font-size: 2.2rem;
}
