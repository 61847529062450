.container {
  width: 40px;
  border: 1px solid black;
  height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 0 1.5px;
  cursor: pointer;
  position: relative;
}

.circle {
  height: 20px;
  aspect-ratio: 1/1;
  background: black;
  border-radius: 100%;
  margin-left: 0;
  transition: all 0.3s ease-in-out;
}

.circle_on {
  margin-left: auto;
}

.container p {
  position: absolute;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  top: -30px;
  left: 100%;
  display: none;
}

.container:hover p {
  display: block;
  z-index: 1;
}
