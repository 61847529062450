.subAdminForm {
  margin: 20px 40px;
}

.subAdminForm > button {
  background-color: #0e81f0;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.emailField {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-top: 10px;
}
input[type="email"] {
  padding: 4px;
  border-radius: 8px;
}
.emailField > label {
}

.remainingFields {
}

.fieldcard {
}

.fieldOptions {
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
}

.item {
  display: flex;
  flex-direction: column;
}

.item > span {
  font-weight: 600;
}

.item > label {
  display: flex;
  gap: 6px;
  cursor: pointer;
}
