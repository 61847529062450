.container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
}
.item {
  display: flex;
  justify-content: space-between;
}

.item p {
  font-size: 0.9rem;
}

.progressContainer {
  width: 100%;
  background-color: white;
  margin-top: 2px;
}

.progress {
  height: 2px;
  border-radius: 25px;
  background-color: #0e81f0;
}
