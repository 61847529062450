.container{
    width: 100%;
    padding: 5px;
}
.Heading{
    font-weight: 400;
    padding: 0 8px;
    color: #397EE6;
    font-size: 1.2rem;
}
.filterContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:0 10px ;
}
.chartContainer{

}
.header{
    display: flex ;
    justify-content: space-between;
    align-items: center;
    margin:  5px 15px;
    margin-top: 20px;
}
.chartArea{
    height: 200vh;
    overflow-y: auto;
}
.date{
    display: flex;
    align-items: center;
    gap: 15px;
}