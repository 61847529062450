.modal_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
  font-size: 18px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.form {
  margin-top: 20px;
  min-height: 20rem;
}

.option {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.option input {
  margin-top: 5px;
  margin-right: 10px;
}

.option label {
  font-size: 16px;
}

.option p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.printButton {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  padding: 8px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxContainer input {
  margin-right: 5px;
}
