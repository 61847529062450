.configuration_container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-inline: auto;
  padding: 30px 0;
}

.import_container {
  width: 100%;
  border: 2px dashed var(--brand-color);
  padding: 50px;
  border-radius: 15px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  gap: 1rem;
}

.choose_btn {
  background: var(--brand-color);
  color: white;
  border-radius: 5px;
  display: flex;
  height: 35px;
  width: fit-content;
  align-items: center;
  position: relative;
}

.choose_btn p {
  padding-inline: 10px;
}

.choose_btn input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.choose_btn_icon_section {
  height: 100%;
  aspect-ratio: 1/1;
  border-left: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.choose_btn svg {
  height: 15px;
  width: 15px;
}

.choose_btn svg path {
  fill: white;
}

.choose_btn_drop_down {
  position: absolute;
  height: 300px;
  width: 300px;
  background: white;
  top: 110%;
  right: 0;
}

.import_icon {
  background: white;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.import_icon svg {
  height: 40px;
  width: 40px;
}

.sample_text {
  font-size: 0.8rem;
  margin-top: 5px;
}

.sample_text span {
  color: var(--brand-color);
  text-decoration: underline;
}

.files {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid grey;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;
}

.file {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.file_icon {
  height: 80px;
  width: 80px;
}

.file_icon path {
  stroke: var(--brand-color);
}

.file_name {
  font-size: 0.8rem;
}

.remove_file {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.remove_file svg {
  height: 0.9rem;
  width: 0.9rem;
}
