.field_contianer {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.field_contianer p {
  font-size: 0.9rem;
  font-weight: 500;
  transform: translateX(5px);
  color: #464646;
}

.select_container {
  height: fit-content;
  width: 100%;
  font-size: 18px;
  /* background-color: rgb(222, 222, 222); */
  border: 1px solid rgb(222, 222, 222);
  border-radius: 10px;
  position: relative;
}

.select_container svg {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  height: 20px;
  width: 50px;
}

.select_container select {
  height: 50px;
  width: 100%;
  /* border: 1px solid grey; */
  border: none;
  /* background-color: rgb(211, 211, 211); */
  border: 1px solid rgb(222, 222, 222);
  padding: 10px;
  font-size: 0.9rem;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border-radius: 10px;
  z-index: 5;
}

.select_container select:focus {
  outline: 1px solid grey;
}

.select_container option {
  text-transform: uppercase;
}

.input_container {
  height: 50px;
  overflow: hidden;
  position: relative;
}

.input_container_add {
  height: fit-content;
  overflow: hidden;
  position: relative;
  display: grid;
  gap: 15px;
}
.input_container_add span {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}

.input_container_add input {
  border: none;
  border-radius: 10px;
  border: 1px solid rgb(222, 222, 222);
  padding: 10px;
  height: 100%;
  width: 100%;
  font-size: 0.9rem;
  height: 60px;
}
.input_container_add input:focus {
  outline: none;
  border: 1px solid grey;
}

.input_container input {
  border: none;
  border-radius: 10px;
  border: 1px solid rgb(222, 222, 222);
  padding: 10px;
  height: 100%;
  width: 100%;
  font-size: 0.9rem;
}

.input_container input[type="number"]::-webkit-outer-spin-button,
.input_container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_container input[type="number"] {
  -moz-appearance: textfield;
}

.input_container input:focus {
  outline: none;
  border: 1px solid grey;
}

.side_icon {
  position: absolute;
  z-index: 10;
  background: transparent;
  right: 0;
  top: 0;
  height: 100%;
  aspect-ratio: 1/1;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
}

.error {
  border: 2px solid red;
  background: #fde1dc;
  outline: none;
}

.error_text {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.error_text p {
  color: #9d382f;
}

.textarea {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.9rem !important;

  border: none;
  border-radius: 10px;
  border: 1px solid rgb(222, 222, 222) !important;

  padding: 10px;
  min-height: 100px;
  width: 100%;
  font-size: 18px;
  background: transparent !important;
}
