.event_main {
  padding: 25px;
}

.event_main h1 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.tabs_section {
  /* padding-inline: 20px; */
  border-bottom: 1px solid grey;
  display: flex;
}

.tabs_section div {
  padding-inline: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}

.tab_selected {
  font-weight: 600;
  border-bottom: 2px solid var(--brand-color);
}

.secondary_content {
  padding: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
}
.secondary_content span,
.secondary_content_2 span {
  font-size: 0.8rem;
  color: grey;
}

.secondary_content p,
.secondary_content_2 p {
  font-size: 0.9rem;
}
.image_section {
  margin-top: 20px;
  height: 25vh;
  width: fit-content;
}
.image_section img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* users details  */

.dashboard {
  padding-top: 1rem;
  background-color: #f9fafa;
  font-family: Arial, sans-serif;
}

.tableWrapper {
  overflow-x: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  color: #333;
}

.table th,
.table td {
  text-align: left;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.table th {
  background-color: #f3f4f6;
  color: #555;
  font-weight: 600;
}

.table tr:hover {
  background-color: #f9f9f9;
}

.table tr:last-child td {
  border-bottom: none;
}

.status {
  padding: 0.3rem 0.7rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: bold;
  display: inline-block;
}

.approved {
  background-color: #d4edda;
  color: #155724;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #6c757d;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.iconButton:hover {
  color: #007bff;
}

.iconButton:last-child {
  margin-right: 0;
}
